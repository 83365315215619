export default {
    main: {
        message: '',
        required_field: 'Campo requerido',
        form_invalid: 'Por favor, complete todos los campos requeridos',
        logout: 'Desconectar',
        error_main: 'An error occurred',
        fill_organization: 'Por favor, crea tu organización'
    },
    landing: {
        header: {
            home: 'HOME',
            who: 'QUIÉNES SOMOS',
            we_do: 'QUÉ HACEMOS',
            for: 'PARA QUIÉN',
            solucion: 'NUESTRA SOLUCIÓN',
            contact_us: 'HABLA CON NOSOTROS'
        },
        texts_background: {
            title: 'una plataforma para la gestión inteligente de tu recorrido de impacto.',
            subtitle:
                'Descubre la nueva solución de la SEALL que apoya organizaciones, emprendedores sociales y empresas de impacto a tomar decisiones basadas en datos que importan.',
            button: '¡QUIERO PROBAR! '
        },
        texts_aside: {
            title:
                'Hoy en día, quien mide el impacto generado por su organización está un paso adelante.',
            text:
                'nació para sanar la necesidad de las organizaciones de hacer un manejo de impacto responsable y transparente. Por medio de datos confiables, líderes conscientes pueden tomar decisiones cada vez más asertivas que generen mayor valor para sus públicos estratégicos.',
            subtile:
                'El objetivo es que líderes puedan gestionar datos sobre su organización de forma transparente y sencilla.',
            button: '¡QUIERO PROBAR!'
        },
        texts_cards: {
            title:
                'Esta es una plataforma gratuita para quien quiera tener en sus manos información clara para:',
            card1:
                'Generar datos a partir de una metodología basada en estándares internacionalmente reconocidos',
            card2:
                'Tener claridad y datos confiables para tomar decisiones más efectivas y fundamentar su estrategia',
            card3: 'Presentar datos relevantes para socios y posibles inversionistas y/o clientes',
            card4: 'Chequear y mejorar sus procesos de manera continua',
            card5: 'Mantener sus datos actualizados',
            card6: 'Chequear el cumplimento de metas',
            card7: 'Unificar la visión del impacto',
            card8: 'Escalar el impacto de su organización',
            card9: 'Medir el impacto de forma transparente'
        },
        texts_box: {
            title:
                'Para que tu organización se fortalezca y tenga una visión completa del impacto que genera en el mundo, facilitamos tu proceso de medición. Ponemos nuestra metodología a tu disposición para el manejo efectivo e innovador del impacto de tu organización.',
            button: '¡QUIERO PROBAR!'
        },
        texts_who: {
            title: '¿Quién hace la SEALL Intelligence?',
            subtitle:
                'SEALL es una startup de impacto que recibió inversión del Grupo Houer en 2020.Juntos reforzamos nuestro deseo de conducir organizaciones en la creación de un mundo mejor.',
            text: 'Nuestra misión es ayudarte a fortalecer el legado de tu organización.'
        },
        texts_wedo: {
            title: '¿Qué hacemos?',
            subtitle:
                'Entre nuestros servicios tenemos la SEALL Intelligence, una plataforma exclusiva de la gestión de impacto. ',
            button: '¡QUIERO PROBAR!'
        },
        texts_people: {
            title:
                'Contribuimos con líderes y organizaciones que administran la complejidad de comprobar el impacto de sus actividades.'
        },
        texts_solucion: {
            title:
                'Simplificamos el entendimiento de matrices globales para democratizar y optimizar la gestión del impacto socioambiental y económico de proyectos, iniciativas y organizaciones.'
        }
    },
    login: {
        main: {
            create: 'Crea tu cuenta',
            login: 'Accede a tu cuenta ',
            email: 'Email',
            pass: 'Contraseña',
            placeh_email: '',
            placeh_pass: '',
            text: 'Basado en la Teoría del Cambio',
            email_invalid: 'E-mail no válido',
            password_invalid:
                'La contraseña debe contener un carácter en minúsculas, un carácter en mayúsculas, un número, un carácter especial y una longitud mínima de 8 caracteres en total',
            error_login: 'Email o contraseña no válidos'
        },
        create: {
            term:
                'Crear una cuenta significa que estás de acuerdo con los Términos de Servicio, Política y Privacidad',
            button: 'Crear mi mapa de transformación',
            message:
                'Para tu seguridad, ingresa abajo el código que recibiste en tu correo electrónico',
            placeh_code: 'Código de activación',
            resend: 'Reenviar código',
            confirm: 'Confirmar código',
            back: 'Volver',
            use_term: '',
            button_close: '',
            terms: ''
        },
        login: {
            link: 'Olvidé mi contraseña',
            button: 'Acceder a mi cuenta'
        },
        forgot: {
            title: 'Recuperação de Senha',
            subtitle:
                'Ao solicitar a recuperação de senha, será enviado um código verificador para o e-mail informado',
            button_next: 'Solicitar',
            verification_code: 'Código de verificação',
            email: 'E-mail',
            new_password: 'Nova senha',
            recover_password: 'Recuperar senha',
            message_success: 'Senha alterada com sucesso!',
            message_warn_two:
                'Algum error aconteceu ao alterar a senha, o código de verificação pode ser inválido.',
            message_warn: 'Algum error aconteceu, tente novamente mais tarde.'
        }
    },
    presentation: {
        title: 'Empieza tu recorrido de impacto',
        subtile:
            'En algunas etapas conseguirás comprender, medir y administrar el impacto de tu empresa en el mundo.',
        button_top: 'Quiero ser un líder de impacto',
        button_bottom: 'Quiero empezar mi recorrido ahora',
        cards: {
            card1: {
                title: 'Mapa de la Transformación',
                description:
                    'Usamos el abordaje de la Teoría del Cambio para ayudarte a reflexionar y comunicar las primeras visiones de tu impacto, incluida una primera orientación a la Agenda 2030 con nuestro Radar de Orientación Estratégica a los ODS.',
                type: 'FREE'
            },
            card2: {
                title: 'Percepción de los Públicos',
                description:
                    'Puedes recolectar la percepción de impacto de tus stakeholders internos y externos y elegir los protocolos más relevantes para tu empresa.',
                type: 'BASIC'
            },
            card3: {
                title: 'Matriz de Materialidad',
                description:
                    'Haz material lo que es intangible. Con la materialidad en tus manos tendrás claridad sobre las principales temáticas de impacto que necesitarás trabajar en tu organización.',
                type: 'BASIC'
            },
            card4: {
                title: 'Matriz de Indicadores',
                description:
                    'Elige los indicadores que tu organización ya monitorea y obtén claridad sobre cuáles faltan todavía o deberían ser incluidos para evaluar tu impacto.',
                type: 'INTERMEDIO'
            },
            card5: {
                title: 'Administración del Impacto',
                description:
                    '¡Aquí podrás chequear día a día el cambio que haces en el mundo y transformarte  en un líder de impacto! Nuestro sistema permite una administración efectiva de cada indicador y de las acciones relacionadas.',
                type: 'INTERMEDIO'
            },
            card6: {
                title: 'Resultados del Impacto',
                description:
                    'Obtén acceso a un reporte compatible con los principales datos y resultados de tu impacto para una comunicación más efectiva con tus stakeholders, incluso con inversionistas e instituciones de financiación.',
                type: 'INTERMEDIO'
            }
        }
    },
    questions: {
        title: 'Empieza tu mapa de la transformación',
        subtitle:
            'Para empezar, necesitamos algo de información que podrá guiar nuestro recorrido. ¿Empezamos?',
        info:
            'De acuerdo con tu organización o iniciativa, es fundamental que completes con cuidado y atención todos los campos. Esta información nos guiará en todo el recorrido y será la base de tu mapa de transformación.',
        button_top: 'Quiero ser un líder de impacto',
        button_bottom: 'Guardar y continuar',
        how_to_use:
            'Presione enter para agregar un elemento y backspace para eliminar el último elemento ingresado',
        save: 'Guardar',
        cards: {
            card1: 'Mapa de la Transformación',
            card2: 'Percepción de los Públicos',
            card3: 'Matriz de Materialidad',
            card4: 'Matriz de Indicadores',
            card5: 'Administración del Impacto',
            card6: 'Resultado del Impacto'
        },
        index: {
            tipo: 'Tipo',
            nome: 'Nombre',
            missao: 'Misión',
            paraquem: 'Para quién',
            problema: 'Problema',
            solucao: 'Solución',
            atividades: 'Actividades',
            efeitos: 'Efectos medibles',
            resultados: 'Resultados',
            impacto: 'Impacto'
        },
        questions: {
            question: {
                question: 'En esta etapa nos ocuparemos de:',
                option1: 'Tu organización',
                option2: 'Una iniciativa de la organización'
            },
            question0: {
                question: 'Nombre de la iniciativa',
                placeholder: '¿Cómo deseas llamar a tu iniciativa?'
            },
            question1: {
                question: 'Misión',
                placeholder: '¿Cuál es la misión de tu iniciativa?'
            },
            question2: {
                question: 'Para quién',
                placeholder: '¿Quiénes serán las personas impactadas y/o beneficiadas por ella?',
                items: 'Maestros, Alumnos, Escuelas en la ciudad de Campinas, SP'
            },
            question3: {
                question: 'Comprensión del problema',
                placeholder: '¿Qué problema resuelve tu iniciativa? Si tu iniciativa no existiera',
                items: 'Falta de infraestructura, Falta de tiempo'
            },
            question4: {
                question: 'Solución',
                placeholder: '',
                items:
                    'Clases de Educación a Distancia, Eventos presenciales, Conferencias, Productos'
            },
            question5: {
                question: 'Actividades',
                placeholder: '',
                items: 'Capacitación de maestros, Formación de alumnos'
            },
            question6: {
                question: 'Efectos medibles',
                placeholder: '',
                items: 'Cantidad de maestros capacitados, Tasas de presencia, NPS'
            },
            question7: {
                question: 'Resultados',
                placeholder: '',
                items: 'Cantidad de maestros capacitados'
            },
            question8: {
                question: 'Impacto',
                placeholder: '',
                items:
                    'Mapeo de nuevas tecnologías, Desarrollo local, Fortalecimiento del comercio, Reducción de la mortalidad infantil'
            }
        },
        modal: {
            title: '¡Felicitaciones!',
            subtitle: 'Has empezado tu recorrido de transformación.',
            info:
                'Necesitamos conocerte un poco más para poder ayudarte en este camino. Por favor, completa ahora algunos datos sobre tu organización.',
            button_go: 'Continuar',
            button_back: 'Volver'
        }
    },
    organization: {
        title: 'Perfil de la organización',
        subtitle:
            'Completa usando los datos de tu organización. No te preocupes, serán usados solo para garantizar que tu mapa sea exclusivo y personalizado.',
        button_top: 'Quiero ser un líder de impacto',
        button_bottom: 'Guardar y continuar',
        cards: {
            card1: 'Mapa de la Transformación',
            card2: 'Percepción de los Públicos',
            card3: 'Matriz de Materialidad',
            card4: 'Matriz de Indicadores',
            card5: 'Administración del Impacto',
            card6: 'Resultado del Impacto'
        },
        itens: {
            avatar: 'Añadir avatar',
            name: 'Nombre de la organización',
            name_placeholder: 'Escribe aquí',
            cnpj: 'Código de identificación de la empresa',
            cnpj_placeholder: 'Escribe aquí',
            segment: 'Área/Segmento',
            segment_placeholder: 'Escribe aquí',
            type: 'Tipo de Organización',
            type_placeholder: 'Seleccione una opción',
            number: 'Cantidad de empleados',
            number_placeholder: 'Escribe aquí',
            city: 'Ciudad',
            city_placeholder: 'Escribe aquí',
            state: 'Provincia',
            state_placeholder: 'Escribe aquí',
            country: 'País',
            country_placeholder: 'Escribe aquí'
        },
        types: {
            public: 'Publica',
            private: 'Privada',
            third_sector: 'Tercer Setor',
            impact_business: 'Impacto Empresarial'
        },
        areas: {
            A: {
                value: 'A',
                text: 'AGRICULTURA, PECUÁRIA, PRODUÇÃO FLORESTAL, PESCA E AQÜICULTURA'
            },
            B: { value: 'B', text: 'INDÚSTRIAS EXTRATIVAS' },
            C: { value: 'C', text: 'INDÚSTRIAS DE TRANSFORMAÇÃO' },
            D: { value: 'D', text: 'ELETRICIDADE E GÁS' },
            E: {
                value: 'E',
                text: 'ÁGUA, ESGOTO, ATIVIDADES DE GESTÃO DE RESÍDUOS E DESCONTAMINAÇÃO'
            },
            F: { value: 'F', text: 'CONSTRUÇÃO' },
            G: { value: 'G', text: 'COMÉRCIO; REPARAÇÃO DE VEÍCULOS AUTOMOTORES E MOTOCICLETAS' },
            H: { value: 'H', text: 'TRANSPORTE, ARMAZENAGEM E CORREIO' },
            I: { value: 'I', text: 'ALOJAMENTO E ALIMENTAÇÃO' },
            J: { value: 'J', text: 'INFORMAÇÃO E COMUNICAÇÃO' },
            K: { value: 'K', text: 'ATIVIDADES FINANCEIRAS, DE SEGUROS E SERVIÇOS RELACIONADOS' },
            L: { value: 'L', text: 'ATIVIDADES IMOBILIÁRIAS' },
            M: { value: 'M', text: 'ATIVIDADES PROFISSIONAIS, CIENTÍFICAS E TÉCNICAS' },
            N: { value: 'N', text: 'ATIVIDADES ADMINISTRATIVAS E SERVIÇOS COMPLEMENTARES' },
            O: { value: 'O', text: 'ADMINISTRAÇÃO PÚBLICA, DEFESA E SEGURIDADE SOCIAL' },
            P: { value: 'P', text: 'EDUCAÇÃO' },
            Q: { value: 'Q', text: 'SAÚDE HUMANA E SERVIÇOS SOCIAIS' },
            R: { value: 'R', text: 'ARTES, CULTURA, ESPORTE E RECREAÇÃO' },
            S: { value: 'S', text: 'OUTRAS ATIVIDADES DE SERVIÇOS' },
            T: { value: 'T', text: 'SERVIÇOS DOMÉSTICOS' },
            U: {
                value: 'U',
                text: 'ORGANISMOS INTERNACIONAIS E OUTRAS INSTITUIÇÕES EXTRATERRITORIAIS'
            }
        }
    },
    legacy: {
        title: 'Estás a pocas preguntas de tu mapa',
        subtitle:
            'Responde las preguntas que siguen: son ellas las que, de forma totalmente gratuita, nos ayudarán a comprender la relación de tus iniciativas con la Agenda 2030 y los Objetivos de Desarrollo Sostenible. Este proceso llevará como máximo 10 minutos, y será solo el inicio de tu recorrido en dirección a la gestión inteligente del impacto de tu organización.',
        info: 'Según tu mirada, indica cuál es la contribución de tu iniciativa para:',
        button_top: 'Quiero ser un líder de impacto',
        button_bottom: 'Guardar y continuar',
        tooltip: 'Haga clic en los números de las preguntas para navegar entre ellos.',
        scale: {
            bad: 'Ninguna contribución',
            good: 'Muy alta contribución'
        },
        cards: {
            card1: 'Mapa de la Transformación',
            card2: 'Percepción de los Públicos',
            card3: 'Matriz de Materialidad',
            card4: 'Matriz de Indicadores',
            card5: 'Administración del Impacto',
            card6: 'Resultado del Impacto'
        },
        questions: {
            question0:
                'Acabar con el hambre, generar acceso a alimentos seguros y de calidad, promover y apoyar la nutrición adecuada y reducir las pérdidas y los desperdicios de alimentos.',
            question1:
                'Promover el bienestar y la salud, generar acceso a tratamientos y medicaciones, crear conciencia y reducir las incidenciasy las muertes  enfermedades.',
            question2:
                'Promover y generar acceso a servicios básicos y esenciales, como los de vivienda, infraestructura, acceso a la energía, tecnología, servicios financieros, entre otros.',
            question3:
                'Posibilitar el acceso a la educación y calificación, formal o informal, en nivel básico, técnico o superior, de forma inclusiva y capaz de promover el aprendizaje y la profesionalización, incluyendo la educación para el desarrollo sostenible.',
            question4:
                'Apoyar y desarrollar la agricultura de forma sostenible, resiliente e innovadora, apoyando el desarrollo y promoviendo la generación de empleo e ingresos.',
            question5:
                'Fortalecer la toma de decisiones responsables, las prácticas de transparencia y anticorrupción y la gestión eficiente de las organizaciones públicas y privadas.',
            question6:
                'Promover y apoyar el desarrollo y la generación de energías limpias, con fuentes renovables y seguras, capaces de contribuir con la mejora de la eficiencia energética y la mitigación de los cambios climáticos.',
            question7:
                'Promover e incentivar el desarrollo económico, la generación de empleo e ingresos, la diversificación y la modernización de las economías, además de valorizar la industria, el emprendedurismo, los negocios locales y los pequeños o micro-empresarios y productores.',
            question8:
                'Garantizar y promover los derechos fundamentales, con respecto y concordancia con la legislación y con el desarrollo sostenible.',
            question9:
                'Garantizar y promover el acceso al saneamiento seguro y de calidad, y al manejo responsable del agua y de la cloaca. Contribuir para la universalización del acceso al agua potable en localidades en situación de vulnerabilidad y para la reducción de los incidentes y enfermedades por falta de saneamiento.',
            question10:
                'Garantizar y promover la igualdad de oportunidades para todas las personas, la igualdad de género, el respecto a las personas con discapacidad, el respecto a todas las diferencias, la no discriminación y la participación colectiva.',
            question11:
                'Promover o incentivar inversiones financieras para el desarrollo de la infraestructura, la ciencia y la tecnología, y de la medicina y los mercados en favor del desarrollo sostenible de la sociedad y de las instituciones.',
            question12:
                'Promover y garantizar la seguridad de todos y el acceso a la justicia, combatir todas las formas de violencia, enfrentar la ocurrencia de crímenes y delitos en todos los niveles.',
            question13:
                'Desarrollar y apoyar acciones en favor de la calidad ambiental, para incentivar la reducción y la mitigación de la polución del agua, del aire y del suelo, así como la preservación y la manutención de la biodiversidad (fauna y flora).',
            question14:
                'Erradicar la pobreza y la vulnerabilidad en todas sus dimensiones, para todas las personas, en todos los lugares.',
            question15:
                'Promover el uso responsable y sostenible de los recursos naturales, incluida la reducción de los residuos generados mediante prácticas de reciclaje y reutilización.',
            question16:
                'Reducir y controlar riesgos sociales y ambientales, como desastres, accidentes, epidemias, contaminaciones y otros.'
        },
        modal: {
            title: '¡Felicitaciones!',
            subtitle: 'Estás cada vez más cerca de ser un líder de impacto',
            maissubtitle:
                'Ha completado el registro, haga clic en continuar para ver el mapa de impacto social',
            info:
                'Evalúa en una escala del 1 al 5 la dificultad del cuestionario, considerando “1” muy fácil y “5” muy difícil.',
            button_go_mais: 'Continuar a MAIS',
            button_go_radar: 'Generar radar de alineación OSC',
            button_go: 'Continuar',
            button_back: 'Volver',
            scale: {
                bad: 'Muy difícil',
                good: 'Muy fácil'
            }
        }
    },
    map: {
        title: 'INICIATIVA',
        mission: 'Misión',
        map: 'Mapa de la transformación',
        map_info: '¡Conecta los puntos! Vincula los hechos que tienen relación directa.',
        radar: 'Radar de Alineamiento Estratégico aos ODS A los ODS',
        radar_info:
            'Vea cómo resulta más fácil percibir cómo tus acciones están alineados a los ODS',
        button_top: 'Quiero ser un líder de impacto',
        button_bottom: 'Quiero ser un líder de impacto',
        button_share: 'Compartir',
        button_download: 'Descargar',
        button_edit: 'Editar',
        button_pdf: 'Descargar mapa en PDF',
        cards: {
            card1: 'Mapa de la Transformación',
            card2: 'Percepción de los Públicos',
            card3: 'Matriz de Materialidad',
            card4: 'Matriz de Indicadores',
            card5: 'Administración del Impacto',
            card6: 'Resultado del Impacto'
        },
        mapwords: {
            para: {
                nome: 'Para',
                itens: ['Maestros', 'Alumnos', 'Escuelas en la ciudad de Campinas, SP']
            },
            problemas: {
                nome: 'Que tienen problemas de:',
                itens: ['Falta de infraestructura', 'Falta de tiempo']
            },
            propomos: {
                nome: 'Proponemos soluciones:',
                itens: [
                    'Clases de Educación a Distancia',
                    'Eventos presenciales',
                    'Conferencias',
                    'Productos'
                ]
            },
            atividades: {
                nome: 'Cuyas actividades clave serán:',
                itens: ['Capacitación de maestros', 'Formación de alumnos']
            },
            medimos: {
                nome: 'Donde medimos:',
                itens: ['Cantidad de maestros capacitados', 'Tasas de presencia', 'NPS']
            },
            resultados: {
                nome: 'Resultados en',
                itens: ['Cantidad de maestros capacitados']
            },
            solucoes: {
                nome: 'Soluciones en',
                itens: [
                    'Mapeo de nuevas tecnologías',
                    'Desarrollo local',
                    'Fortalecimiento del comercio',
                    'Reducción de la mortalidad infantil'
                ]
            }
        },
        texts: {
            click_max: '',
            click_min: '',
            text_card: 'de Alineamiento con los ODS'
        },
        ods: {
            ods1: {
                name: 'Fin de la pobreza',
                desc: 'Poner fin a la pobreza en todas sus formas en todo el mundo',
                text: ''
            },
            ods2: {
                name: 'Hambre Cero',
                desc:
                    'Poner fin al hambre, lograr la seguridad alimentaria y la mejora de la nutrición y promover la agricultura sostenible',
                text: ''
            },
            ods3: {
                name: 'Salud y Bienestar',
                desc:
                    'Garantizar una vida sana y promover el bienestar para todos en todas las edades',
                text: ''
            },
            ods4: {
                name: 'Educación de calidad',
                desc:
                    'Garantizar una educación inclusiva, equitativa y de calidad y promover oportunidades de aprendizaje durante toda la vida para todos',
                text: ''
            },
            ods5: {
                name: 'Igualdad de gênero',
                desc:
                    'Lograr la igualdad entre los géneros y empoderar a todas las mujeres y las niñas',
                text: ''
            },
            ods6: {
                name: 'Agua limpia y saneamiento',
                desc:
                    'Garantizar la disponibilidad de agua y su gestión sostenible y el saneamiento para todos',
                text: ''
            },
            ods7: {
                name: 'Energía asequible y no contaminante',
                desc: 'Garantizar el acceso a una energía asequible, segura, sostenible y moderna',
                text: ''
            },
            ods8: {
                name: 'Trabajo decente y crecimiento econômico',
                desc:
                    'Promover el crecimiento económico sostenido, inclusivo y sostenible, el empleo pleno y productivo y el trabajo decente para todos',
                text: ''
            },
            ods9: {
                name: 'Industria, Innovación e Infraestructura',
                desc:
                    'Construir infraestructuras resilientes, promover la industrialización inclusiva y sostenible y fomentar la innovación',
                text: ''
            },
            ods10: {
                name: 'Reducción de las desigualdades',
                desc: 'Reducir la desigualdad en y entre los países',
                text: ''
            },
            ods11: {
                name: 'Ciudades y comunidades sostenibles',
                desc:
                    'Lograr que las ciudades sean más inclusivas, seguras, resilientes y sostenibles',
                text: ''
            },
            ods12: {
                name: 'Producción y consumo responsables',
                desc: 'Garantizar modalidades de consumo y producción sostenibles',
                text: ''
            },
            ods13: {
                name: 'Acción por el clima',
                desc: 'Adoptar medidas urgentes para combatir el cambio climático y sus efectos',
                text: ''
            },
            ods14: {
                name: 'Vida submarina',
                desc:
                    'Conservar y utilizar sosteniblemente los océanos, los mares y los recursos marinos',
                text: ''
            },
            ods15: {
                name: 'Vida de ecosistemas terrestes',
                desc:
                    'Gestionar sosteniblemente los bosques, luchar contra la desertificación, detener e invertir la degradación de las tierras, detener la pérdida de biodiversidad',
                text: ''
            },
            ods16: {
                name: 'Paz, Justicia e instituciones sólidas',
                desc: 'Promover sociedades justas, pacíficas e inclusivas',
                text: ''
            },
            ods17: {
                name: 'Alianzas para lograr los objetivos',
                desc: 'Revitalizar la Alianza Mundial para el Desarrollo Sostenible',
                text: ''
            },
            sourcing: 'Fonte: ONU'
        },
        share: {
            title: 'Compartilhar meu mapa da transformação',
            subtitle: 'Quem são as pessoas que mais poderiam se interessar por isso?',
            fields: {
                name: 'Nome',
                email: 'E-mail'
            },
            invalid_field: {
                name: 'Invalid Name',
                email: 'Invalid E-mail'
            },
            buttons: {
                cancel: 'Cancelar',
                share: 'Compartilhar Mapa da Transformação'
            },
            email_send_msg: 'Mapa compartilhado com sucesso!',
            bodyEmail: 'está compartilhando o mapa da transformação com você!',
            the: 'A',
            hello: 'Olá'
        }
    },
    header: {
        perfil: 'Mi perfil',
        impacto: 'Mapa de la transformación',
        jornada: 'Mi recorrido',
        ods: 'Los ODS',
        esg: 'Radar ESG'
    },
    mais: {
        header: {
            title: 'Cadastrar OSC'
        },
        form: {
            cnpj: {
                field: 'CNPJ',
                field_error_msg: 'CNPJ inválido'
            },
            name: {
                field: 'Nome',
                field_error_msg: 'Nome inválido'
            },
            country: {
                field: 'País',
                selected: 'Selecione uma país'
            },
            city: {
                field: 'Cidade',
                selected: 'Selecione uma cidade'
            },
            state: {
                field: 'UF',
                selected: 'Selecione um UF'
            },
            telephone: {
                field: 'Telefone'
            },
            sector: {
                field: 'Setor de Atuação'
            },
            site: {
                field: 'Site'
            },
            employeeQuantity: {
                field: 'Número de Benefificados'
            },
            email: {
                field: 'E-mail'
            },
            password: {
                field: 'Senha'
            },
            ceo: {
                field: 'Nome do Responsável',
                field_error_msg: 'Nome do Responsável inválido'
            },
            common: {
                required_field: 'Campo Obrigatório'
            },
            btn: {
                save: 'Acessar Seall Intelligence'
            }
        }
    },
    common: {
        error_http: 'Alguma coisa aconteceu! Tente Novamente'
    }
};
